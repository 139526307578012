import React from 'react';
import PropTypes from 'prop-types';
// import classes from './MinimalNoTopBar.module.scss';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		// display: 'flex',
		minHeight: '100%',
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.background.paper,
		},
	},
	content: {
		flexGrow: 1,
		minHeight: '100%',
		paddingRight: theme.spacing(0),
		// paddingLeft: theme.spacing(9),
		// [theme.breakpoints.up('xs')]: {
		// 	paddingLeft: theme.spacing(9),
		// },
		paddingLeft: theme.spacing(0),
		paddingBottom: 0,
	},
}));

const MinimalNoTopBar = (props) => {
	const { children } = props;

	const classes = useStyles();

	return (
		<div className={classes.root}>
			<main className={classes.content}>{children}</main>
		</div>
	);
};

MinimalNoTopBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default MinimalNoTopBar;
