import _ from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import esLocale from 'date-fns/locale/es';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';

export const IsDesktopHandler = (size) => {
	let media = null;

	switch (size) {
		case 'sm':
			media = '(min-width: 576px)';
			break;
		case 'md':
			media = '(min-width: 768px)';
			break;
		case 'lg':
			media = '(min-width: 992px)';
			break;
		case 'xl':
			media = '(min-width: 1200px)';
			break;
		default:
			media = '(min-width: 992px)';
	}

	const matches = useMediaQuery(media, {
		defaultMatches: true,
	});

	return matches;
};

export const cleanRut = (rut) => {
	return typeof rut === 'string'
		? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()
		: '';
};

export const validateRut = (rut) => {
	if (typeof rut !== 'string') {
		return false;
	}
	if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
		return false;
	}

	rut = cleanRut(rut);

	var t = parseInt(rut.slice(0, -1), 10);
	var m = 0;
	var s = 1;

	while (t > 0) {
		s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
		t = Math.floor(t / 10);
	}

	var v = s > 0 ? '' + (s - 1) : 'K';
	return v === rut.slice(-1);
};

export const formatRut = (rut) => {
	rut = cleanRut(rut);

	var result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1);
	for (var i = 4; i < rut.length; i += 3) {
		result = rut.slice(-3 - i, -i) + '.' + result;
	}

	return result;
};

export const formatOnChangeRut = (e) => {
	return (e.target.value = formatRut(e.target.value));
};

export const dateConvert = (date) => {
	const converted = date.split('-');
	const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
	let parts = newFormat.split('-');
	const toDateFormat1 = new Date(parts[0], parts[1] - 1, parts[2]);
	const toDateFormat2 = format(
		new Date(toDateFormat1),
		'yyyy-MM-dd',
		esLocale
	);
	const toDateFormat3 = new Date(
		new Date(toDateFormat2).getTime() +
			Math.abs(new Date(toDateFormat2).getTimezoneOffset() * 60000)
	);
	return toDateFormat3;
};

export const birthConvert = (date) => {
	const toDateFormat2 = format(new Date(date), 'dd-MM-yyyy', esLocale);

	return toDateFormat2;
};

export const dateFormatIso8601 = (date) => {
	const dateFormat = new Date(date).toISOString();
	const dateNewFormat = dateFormat.substring(0, 10);
	const [yy, mm, dd] = dateNewFormat.split(/-/g);
	return `${dd}/${mm}/${yy}`;
};

export const dateTimeFormatISO = (dateTime) => {
	const result = formatISO(dateTime);
	return result;
};

export const dateFormatIso = (date) => {
	const dateFormat = new Date(date).toISOString();
	const dateNewFormat = dateFormat.substring(0, 10);
	const [yy, mm, dd] = dateNewFormat.split(/-/g);
	return `${yy}-${mm}-${dd}`;
};
// const endFormat = dateFormatIso8601(end);

export const decimalAdjust = (type, value, exp) => {
	if (typeof exp === 'undefined' || +exp === 0) {
		return Math[type](value);
	}
	value = +value;
	exp = +exp;
	// Si el valor no es un número o el exp no es un entero...
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	}
	// Shift
	value = value.toString().split('e');
	value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
	// Shift back
	value = value.toString().split('e');
	return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
};

export const printOrder = (iddiv) => {
	const printableElements = document.getElementById(iddiv).innerHTML;
	const orderHtml =
		'<html><head><title></title></head><body>' +
		printableElements +
		'</body></html>';
	// const oldPage = document.body.innerHTML;
	document.querySelector('head').innerHTML +=
		'<style>body{ background-color: #FFFFFF !important; padding: 10px 20px; }</style>';
	document.querySelector('head').innerHTML +=
		'<link rel="stylesheet"	href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"	integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"/>';

	document.body.innerHTML = orderHtml;
	window.print();
	// document.body.innerHTML = oldPage
	window.location.reload(false);
};

export const numberFormat = (value) => {
	if (!isNaN(parseInt(value))) {
		// console.log("entra en es número")
		return Intl.NumberFormat('de-DE', { maximumFractionDigits: 1 }).format(
			value
		);
	} else {
		// console.log("entra en No es número")
		return '-';
	}
};

export const alterArrayForMulti = (array) => {
	const newArray = array.map((item) => {
		return {
			...item,
			value: item.id,
			label: item.name,
		};
	});
	return newArray;
};

export const alterArrayForMultiRutFullname = (array) => {
	const newArray = array.map((item) => {
		return {
			...item,
			value: item.id,
			label: item.rut + ' - ' + item.fullname,
		};
	});
	return newArray;
};

export const alterArrayForQuery = (array) => {
	const newArray = array.map((item) => {
		return _.omit(item, ['label', 'value']);
	});
	return newArray;
};

export const alterArrayGetIds = (array) => {
	const newArray = array.map((item) => {
		return _.pick(item, ['id']);
	});
	return newArray;
};

// otra opción para el ommit
// const omit = (keyToOmit, { [keyToOmit]: _, ...omittedPropObj } = {}) => omittedPropObj;

// --------Asignador
export const notIn = (a, b) => {
	return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a, b) => {
	return a.filter((value) => b.indexOf(value) !== -1);
};

export const arrayDifferenceElements = (array1, array2) => {
	return _.differenceBy(array1, array2, 'id');
};

export const dateConvertSlash = (date) => {
	const converted = date.split('/');
	const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
	return newFormat;
};

export const dateConvertSlashNormal = (date) => {
	const converted = date.split('/');
	const newFormat = converted[0] + '-' + converted[1] + '-' + converted[2];
	return newFormat;
};

export const alterArrayGetFields = (array, arrayFields) => {
	const newArray = array.map((item) => {
		return _.pick(item, arrayFields);
	});
	return newArray;
};

export const dateTimeFormatISOZeroTimeZone = (dateTime) => {
	let date = new Date(dateTime.getTime());
	date.setHours(0, 0, 0, 0);
	const result = formatISO(date);
	let dateTimeZero = result.slice(0, -6);
	return dateTimeZero + '+00:00';
};

export const getYearsFromBirth = (birthDate) => {
	const actualYear = new Date().getFullYear();
	let yearDate = format(new Date(birthDate), 'yyyy-MM-dd', esLocale);
	yearDate = format(new Date(yearDate), 'yyyy', esLocale);
	return actualYear - parseInt(yearDate);
};

export const dateConvertString = (date) => {
	const converted = date.split('/');
	const newFormat = converted[2] + '-' + converted[1] + '-' + converted[0];
	let parts = newFormat.split('-');
	const toDateFormat1 = new Date(parts[0], parts[1] - 1, parts[2]);
	const toDateFormat2 = format(
		new Date(toDateFormat1),
		'yyyy-MM-dd',
		esLocale
	);
	const toDateFormat3 = new Date(
		new Date(toDateFormat2).getTime() +
			Math.abs(new Date(toDateFormat2).getTimezoneOffset() * 60000)
	);
	return toDateFormat3;
};

export const renderArrayText = (array, field) => {
	let textReturn = 'no especifica';
	if (array && array.length > 0) {
		let itemQuantity = array.length - 1;
		// let textReturn = '';
		textReturn = array.map((item, index) => {
			// console.log(itemQuantity, '---', index);
			if (index < itemQuantity) {
				return `${item[field]} - `;
			} else {
				return `${item[field]}`;
			}
		});
	}
	return textReturn;
};
