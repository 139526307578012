// import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const original = createTheme();

const theme = createTheme({
	palette,
	typography: typography,
	overrides,
	shape: {
		borderRadius: 6,
	},
	shadows: [
		...original.shadows,
		'inset 0px 5px 9px -10px rgba(0, 0, 0, 0.2)',
	],
	zIndex: {
		appBar: 1200,
		drawer: 1100,
	},
});

export default theme;
