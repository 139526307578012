import React, { useState } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Context from './context/Context';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import Routes from './Routes';
import SnackBarsComponent from './components/SnackBarsComponent';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw,
});

const App = () => {
	const [errorState, setErrorState] = useState(false);
	const [errorMessageState, setErrorMessageState] = useState('');

	// const seeErrors = (graphQLErrors, networkError) => {
	// 	let message = '';
	// 	if (graphQLErrors) {
	// 		message = message.concat('ERROR - ');
	// 		graphQLErrors.map((err) => {
	// 			message = message.concat(err.message);
	// 			switch (err.extensions.code) {
	// 				case 'UNAUTHENTICATED':
	// 					window.sessionStorage.removeItem('token');
	// 					//window.location.href = '/';
	// 					break;
	// 				default:
	// 					break;
	// 			}
	// 			return err;
	// 		});
	// 	}
	// 	if (networkError) {
	// 		message = message.concat(
	// 			'ERROR - Aplicación no puede conectarse con el servidor'
	// 		);
	// 	}
	// 	setErrorState(true);
	// 	setErrorMessageState(message);
	// };

	const handleCloseSnackBarsComponent = () => {
		setErrorState(false);
		setErrorMessageState('');
	};

	// const cleanTypenameFieldLink = new ApolloLink((operation, forward) => {
	// 	const token = window.sessionStorage.getItem('token');
	// 	const authorization = token ? `${token}` : '';
	// 	operation.setContext({
	// 		headers: {
	// 			authorization,
	// 		},
	// 	});
	// 	return forward(operation);
	// });

	// const testError = onError(({ graphQLErrors, networkError }) => {
	// 	seeErrors(graphQLErrors, networkError);
	// });

	// const uri = process.env.REACT_APP_API_QRSCAN_URL;
	// const uri = 'http://localhost:4003/graphql';
	// const uploadLink = createUploadLink({ uri });

	// const client = useMemo(
	// 	() =>
	// 		new ApolloClient({
	// 			link: ApolloLink.from([
	// 				cleanTypenameFieldLink,
	// 				testError,
	// 				uploadLink,
	// 			]),
	// 			cache: new InMemoryCache(),
	// 		}),
	// 	[]
	// );

	return (
		<Context.Provider>
			{/* <ApolloProvider client={client}> */}
			<BrowserRouter>
				<React.Fragment>
					<ThemeProvider theme={theme}>
						<Router history={browserHistory}>
							<Routes />

							{errorState && (
								<Snackbar
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									open={errorState}
									onClose={() => setErrorState(false)}
									autoHideDuration={4500}
									key={Math.random()}
								>
									<SnackBarsComponent
										variant="error"
										message={errorMessageState}
										handleClose={
											handleCloseSnackBarsComponent
										}
									/>
								</Snackbar>
							)}
						</Router>
					</ThemeProvider>
				</React.Fragment>
			</BrowserRouter>
			{/* </ApolloProvider> */}
		</Context.Provider>
	);
};
export default App;
