// import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
// import Highcharts from 'highcharts';
import DartIcon from '../../assets/Vectordardo.png';
import TwigIcon from '../../assets/Vectorramilla.png';
import CherryIcon from '../../assets/cherryicon.png';
// import HighchartsReact from 'highcharts-react-official';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardContent, makeStyles, useTheme } from '@material-ui/core';
import { BsContainer, BsRow, BsCol } from '../../layouts/components';
import classes from './Dashboard.module.scss';
import axios from 'axios';
import CircularProgressComponent from '../../components/Progress/CircularProgressComponent/CircularProgressComponent';
import { Alert, AlertTitle } from '@material-ui/lab';
import { numberFormat } from '../../helpers/functionHelpers';

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const sortArrayFunc = (array) => {
	array.sort(function(a, b) {
		if (parseInt(a._id.rowNumber) === parseInt(b._id.rowNumber)) {
			return parseInt(a._id.plantNumber) - parseInt(b._id.plantNumber);
		}
		return parseInt(a._id.rowNumber) > parseInt(b._id.rowNumber) ? 1 : -1;
	});
	return array;
};

const RESPONSE = {
	orchard: 'ORCHARD',
	cc: 'cc',
	quarter: 'quarter',
	rowNumber: '01',
	plantNumber: '10',
	variety: 'variety',
	dart: '23',
	twig: '32',
	counterPercent: '73.5',
	countTagg: '16',
	countCounter: '21',
};

const baseURL = 'https://api.analytics-qr.cl/qrscan';
const local_baseURL = 'http://localhost:3300/qrscan';

const dashDelete = (string = '') => string.replace(/_/g, ' ');

const useStyles = makeStyles((theme) => ({
	tableStyle: {
		fontFamily: theme.typography.fontFamily,
	},
	tableAreaStyle: {
		maxHeight: '370px',
		overflow: 'auto',
	},
	blueLabel: {
		color: theme.palette.secondary.main,
		fontWeight: 600,
		fontSize: 20,
	},
	tableHead: {
		fontFamily: theme.typography.fontFamily,
		textAlign: 'center',
		'& thead tr th': {
			verticalAlign: 'middle',
			borderBottom: 'none',
			borderTop: 'none',
			textAlign: 'center',
			padding: '0.5rem',
		},
	},
	cellFruit: {
		backgroundColor: theme.palette.grey[50],
	},
}));

const Dashboard = () => {
	const [loadingState, setLoadingState] = useState(true);
	const [plantInfoState, setPlantInfoState] = useState(null);
	const [errorState, setErrorState] = useState(false);
	const [errorMessageState, setErrorMessageState] = useState(null);

	// let history = useHistory();

	const actualYear = new Date().getFullYear().toString();

	let query = useQuery();

	const theme = useTheme();

	const classesUI = useStyles();

	const idPlant = query.get('id');

	// console.log(theme, '<----theme');

	const getPlantInfoAsync = async () => {
		try {
			const response = await axios.get(`${baseURL}?id=${idPlant}`);

			console.log(response, '<-response----');

			if (!response.data.error) {
				console.log('entra', response.data);
				setPlantInfoState(response.data.data);
				setErrorState(false);
				setLoadingState(false);
				// console.log('error no es true');
			} else {
				console.log('entra aca');
				setPlantInfoState(null);
				setErrorMessageState(response.data.data);
				setErrorState(true);
				setLoadingState(false);
			}
		} catch (error) {
			// console.log(error);
			// setPlantInfoState(RESPONSE);
			setLoadingState(false);
		}
	};

	useEffect(() => {
		if (idPlant) {
			getPlantInfoAsync();
		} else {
			setLoadingState(false);
			setErrorState(true);
			setErrorMessageState('No hay código');
		}
	}, []);

	const optionsHighCharts = {
		chart: {
			type: 'pie',
			height: 220,
			spacingBottom: 8,
			spacingLeft: 0,
			spacingRight: 5,
			spacingTop: 10,
		},
		title: {
			text:
				plantInfoState &&
				`Contabilizados: ${numberFormat(
					plantInfoState.counterPercent
				)}% <br>(${plantInfoState.countCounter} de ${
					plantInfoState.countTagg
				} plantas contadas)`,
			align: 'center',
			margin: 0,
			style: {
				fontFamily: theme.typography.fontFamily,
				fontSize: 16,
			},
		},
		plotOptions: {
			pie: {
				shadow: false,
				center: ['50%', '50%'],
				dataLabels: {
					enabled: false,
				},
				// showInLegend: true,
			},
		},
		credits: {
			enabled: false,
		},
		tooltip: {
			valueSuffix: '%',
			pointFormat: '<b>{point.percentage:.1f}%</b>',
		},
		series: [
			{
				innerSize: '40%',
				data: [
					{
						name: 'Contadas',
						y: plantInfoState
							? parseFloat(plantInfoState.counterPercent)
							: 0,
						color: theme.palette.secondary.main,
					},
					{
						name: 'No contadas',
						y: plantInfoState
							? 100 - parseFloat(plantInfoState.counterPercent)
							: 100,
						color: 'transparent',
					},
				],
			},
		],
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 500,
					},
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'left',
							verticalAlign: 'bottom',
						},
					},
				},
			],
		},
	};

	return (
		<div className={clsx(classes.root, 'h-100')}>
			{!loadingState ? (
				<BsContainer class="px-0 px-md-3">
					{!errorState ? (
						<>
							<BsRow class="justify-content-between no-gutters">
								<BsCol class="col-md-12 text-center mt-3">
									<Typography
										variant="h3"
										gutterBottom
										className="mb-3"
									>
										Huerto:{' '}
										{dashDelete(plantInfoState.orchard)}
									</Typography>
									<Card>
										<CardContent className="px-2 px-md-5">
											<BsRow class="mt-4 align-items-center">
												<BsCol class="col-md-6">
													{/* <BsCol class="col-md-12"> cuando NO va frutos */}
													<table
														className={clsx(
															'table',
															classesUI.tableStyle
														)}
													>
														<tbody>
															<tr>
																<th scope="row">
																	Centro Costo
																</th>
																<td>
																	{
																		plantInfoState.cc
																	}
																</td>
															</tr>
															<tr>
																<th scope="row">
																	Cuartel
																</th>
																<td>
																	{
																		plantInfoState.quarter
																	}
																</td>
															</tr>
															<tr>
																<th scope="row">
																	Variedad
																</th>
																<td>
																	{
																		plantInfoState.variety
																	}
																</td>
															</tr>
															<tr>
																<th scope="row">
																	Portainjerto
																</th>
																<td>
																	{
																		plantInfoState?.rootStock
																	}
																</td>
															</tr>
															{plantInfoState?.season ===
															actualYear ? (
																<>
																	<tr>
																		<th scope="row">
																			Temporada
																		</th>
																		<td
																			style={{
																				fontWeight:
																					'bold',
																			}}
																		>
																			{
																				plantInfoState?.season
																			}
																		</td>
																	</tr>
																	<tr>
																		<th scope="row">
																			Nº
																			Hilera
																		</th>
																		<td
																			className={
																				classesUI.blueLabel
																			}
																		>
																			{
																				plantInfoState.rowNumber
																			}
																		</td>
																	</tr>
																	<tr>
																		<th scope="row">
																			Nº
																			Planta
																		</th>
																		<td
																			className={
																				classesUI.blueLabel
																			}
																		>
																			{
																				plantInfoState.plantNumber
																			}
																		</td>
																	</tr>
																</>
															) : null}
														</tbody>
													</table>
												</BsCol>
												{/* frutales */}
												<BsCol class="col-md-6">
													<BsRow>
														<BsCol class="col-md-12 text-center">
															<Typography
																variant="h4"
																style={{
																	fontWeight: 600,
																	marginBottom: 20,
																}}
															>
																Frutos
															</Typography>
														</BsCol>
														<BsCol class="col-md-6 mt-3 mt-md-0">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																>
																	Pre Raleo
																</Typography>
																<div
																	className={
																		classes.kpi
																	}
																>
																	<img
																		src={
																			CherryIcon
																		}
																		width="80"
																		className="img-fluid"
																		alt="cherry"
																	/>
																	<Typography
																		variant="h1"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{plantInfoState.fruit ===
																		0
																			? '-'
																			: numberFormat(
																					plantInfoState.fruit
																			  )}
																	</Typography>
																</div>
															</div>
														</BsCol>
														<BsCol class="col-md-6">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography
																	gutterBottom
																	variant="h5"
																>
																	Post Raleo
																</Typography>
																<div
																	className={
																		classes.kpi
																	}
																>
																	<img
																		src={
																			CherryIcon
																		}
																		width="80"
																		className="img-fluid"
																		alt="cherry"
																	/>
																	<Typography
																		variant="h1"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{plantInfoState.fruitPost ===
																		0
																			? '-'
																			: numberFormat(
																					plantInfoState.fruitPost
																			  )}
																	</Typography>
																</div>
															</div>
														</BsCol>
														<BsCol class="col-md-12 text-center">
															<Typography variant="subtitle1">
																Centros frutales
																totales:{' '}
																{plantInfoState.fruit +
																	plantInfoState.fruitPost ===
																0
																	? '-'
																	: numberFormat(
																			parseFloat(
																				plantInfoState.fruit
																			) +
																				parseFloat(
																					plantInfoState.fruitPost
																				)
																	  )}{' '}
															</Typography>
														</BsCol>
													</BsRow>
												</BsCol>
											</BsRow>
											{plantInfoState?.season ===
											actualYear ? (
												<BsRow class="mt-5">
													<BsCol class="col-md-6 mb-4 mb-md-0">
														{/* Pre poda */}
														<BsRow>
															<BsCol class="col-md-12 text-center">
																<Typography variant="h5">
																	Pre Poda
																</Typography>
															</BsCol>
															<BsCol class="col-md-6 mt-3 mt-md-0">
																<div
																	className={
																		classes.kpiContainer
																	}
																>
																	<Typography
																		gutterBottom
																	>
																		Dardos
																	</Typography>
																	<div
																		className={
																			classes.kpi
																		}
																	>
																		<img
																			src={
																				DartIcon
																			}
																			width="40"
																			className="img-fluid"
																			alt="dart"
																		/>
																		<Typography
																			variant="h2"
																			color="secondary"
																			className="mb-0"
																			style={{
																				lineHeight:
																					'42px',
																			}}
																		>
																			{plantInfoState.dart ===
																			0
																				? '-'
																				: numberFormat(
																						plantInfoState.dart
																				  )}
																		</Typography>
																	</div>
																</div>
															</BsCol>
															<BsCol class="col-md-6">
																<div
																	className={
																		classes.kpiContainer
																	}
																>
																	<Typography
																		gutterBottom
																	>
																		Ramillas
																	</Typography>
																	<div
																		className={
																			classes.kpi
																		}
																	>
																		<img
																			src={
																				TwigIcon
																			}
																			width="40"
																			className="img-fluid"
																			alt="dart"
																		/>
																		<Typography
																			variant="h2"
																			color="secondary"
																			className="mb-0"
																			style={{
																				lineHeight:
																					'42px',
																			}}
																		>
																			{plantInfoState.twig ===
																			0
																				? '-'
																				: numberFormat(
																						plantInfoState.twig
																				  )}
																		</Typography>
																	</div>
																</div>
															</BsCol>
															<BsCol class="col-md-12 text-center">
																<Typography variant="subtitle1">
																	Centros
																	frutales
																	totales:{' '}
																	{plantInfoState.dart +
																		plantInfoState.twig ===
																	0
																		? '-'
																		: numberFormat(
																				parseFloat(
																					plantInfoState.dart
																				) +
																					parseFloat(
																						plantInfoState.twig
																					)
																		  )}{' '}
																</Typography>
															</BsCol>
														</BsRow>
													</BsCol>
													<BsCol class="col-md-6">
														{/* post poda */}
														<BsRow>
															<BsCol class="col-md-12 text-center">
																<Typography variant="h5">
																	Post Poda
																</Typography>
															</BsCol>
															<BsCol class="col-md-6 mt-3 mt-md-0">
																<div
																	className={
																		classes.kpiContainer
																	}
																>
																	<Typography
																		gutterBottom
																	>
																		Dardos
																	</Typography>
																	<div
																		className={
																			classes.kpi
																		}
																	>
																		<img
																			src={
																				DartIcon
																			}
																			width="40"
																			className="img-fluid"
																			alt="dart"
																		/>
																		<Typography
																			variant="h2"
																			color="secondary"
																			className="mb-0"
																			style={{
																				lineHeight:
																					'42px',
																			}}
																		>
																			{plantInfoState.dartPost ===
																			0
																				? '-'
																				: numberFormat(
																						plantInfoState.dartPost
																				  )}
																		</Typography>
																	</div>
																</div>
															</BsCol>
															<BsCol class="col-md-6">
																<div
																	className={
																		classes.kpiContainer
																	}
																>
																	<Typography
																		gutterBottom
																	>
																		Ramillas
																	</Typography>
																	<div
																		className={
																			classes.kpi
																		}
																	>
																		<img
																			src={
																				TwigIcon
																			}
																			width="40"
																			className="img-fluid"
																			alt="dart"
																		/>
																		<Typography
																			variant="h2"
																			color="secondary"
																			className="mb-0"
																			style={{
																				lineHeight:
																					'42px',
																			}}
																		>
																			{plantInfoState.twigPost ===
																			0
																				? '-'
																				: numberFormat(
																						plantInfoState.twigPost
																				  )}
																		</Typography>
																	</div>
																</div>
															</BsCol>
															<BsCol class="col-md-12 text-center">
																<Typography variant="subtitle1">
																	Centros
																	frutales
																	totales:{' '}
																	{plantInfoState.dartPost +
																		plantInfoState.twigPost ===
																	0
																		? '-'
																		: numberFormat(
																				parseFloat(
																					plantInfoState.dartPost
																				) +
																					parseFloat(
																						plantInfoState.twigPost
																					)
																		  )}{' '}
																</Typography>
															</BsCol>
														</BsRow>
													</BsCol>
												</BsRow>
											) : null}
										</CardContent>
									</Card>
								</BsCol>
							</BsRow>
							{/* promedios y gráfico */}
							<BsRow class="mt-3 no-gutters">
								<BsCol class="col-md-12">
									<Card>
										<CardContent className="px-2 py-5 px-md-5">
											<BsRow class="align-items-center pt-0">
												{/* se comenta cuando no va frutos */}
												<BsCol class="col-md-12 text-center mb-3">
													<Typography variant="h3">
														Temporada {actualYear}
													</Typography>
												</BsCol>
												<BsCol class="col-md-4">
													<BsRow>
														<BsCol class="col-md-12">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel
																	Frutos Pre
																	Raleo
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPreFruit
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
														<BsCol class="col-md-12">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel
																	Frutos Post
																	Raleo
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPostFruit
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
													</BsRow>
												</BsCol>
												<BsCol class="col-md-8">
													{/* <BsCol class="col-md-12"> cuando NO va frutos */}
													<BsRow>
														{/* <BsCol class="col-md-12 text-center mb-3">
															<Typography variant="h3">
																Temporada{' '}
																{actualYear}
															</Typography>
														</BsCol> */}
														{/* promedio pre poda dardos */}
														<BsCol class="col-md-6">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel Pre
																	Poda Dardos
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPreDart
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
														{/* promedio pre poda ramillas */}
														<BsCol class="col-md-6">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel Pre
																	Poda
																	Ramillas
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPreTwig
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
														{/* promedio post poda dardos */}
														<BsCol class="col-md-6">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel Post
																	Poda Dardos
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPostDart
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
														{/* promedio post poda ramillas */}
														<BsCol class="col-md-6">
															<div
																className={
																	classes.kpiContainer
																}
															>
																<Typography variant="subtitle1">
																	Promedio
																	Cuartel Post
																	Poda
																	Ramillas
																</Typography>
																<div
																	className={
																		classes.prom
																	}
																>
																	<Typography
																		variant="h2"
																		color="secondary"
																		className="mb-0"
																		style={{
																			lineHeight:
																				'42px',
																		}}
																	>
																		{numberFormat(
																			plantInfoState.avgPostTwig
																		)}
																	</Typography>
																</div>
															</div>
														</BsCol>
													</BsRow>
												</BsCol>

												{/* <BsCol class="col-md-6 mt-4 mt-md-0 text-center">
													<Typography variant="h5">
														Pre Poda
													</Typography>
													<HighchartsReact
														highcharts={Highcharts}
														options={
															optionsHighCharts
														}
													/>
												</BsCol> */}
											</BsRow>
										</CardContent>
									</Card>
								</BsCol>
							</BsRow>
							<BsRow class="mt-3 no-gutters">
								<BsCol class="col-md-12">
									<Card>
										<CardContent className="px-2 py-4 px-md-5">
											<BsRow>
												<BsCol class="col-md-12">
													<Typography
														variant="h4"
														className="mb-3"
													>
														Temporada {actualYear}
													</Typography>
													<div
														className={clsx(
															classesUI.tableAreaStyle,
															'table-responsive'
														)}
													>
														<table
															className={clsx(
																'table',
																classesUI.tableHead
															)}
														>
															<thead
																style={{
																	position:
																		'sticky',
																	top: 0,
																	zIndex: 1,
																}}
																className="thead-light"
															>
																<tr>
																	<th
																		rowSpan={
																			2
																		}
																	>
																		Nº
																		Hilera
																	</th>
																	<th
																		rowSpan={
																			2
																		}
																	>
																		Nº
																		Planta
																	</th>
																	<th
																		colSpan={
																			2
																		}
																	>
																		Frutos
																	</th>
																	<th
																		colSpan={
																			2
																		}
																	>
																		Dardos
																	</th>
																	<th
																		colSpan={
																			2
																		}
																	>
																		Ramillas
																	</th>
																</tr>
																<tr>
																	<th>
																		Pre
																		Raleo
																	</th>
																	<th>
																		Post
																		Raleo
																	</th>
																	<th>
																		Pre Poda
																	</th>
																	<th>
																		Post
																		Poda
																	</th>
																	<th>
																		Pre Poda
																	</th>
																	<th>
																		Post
																		Poda
																	</th>
																</tr>
															</thead>
															<tbody>
																{plantInfoState.listPlant &&
																	sortArrayFunc(
																		plantInfoState.listPlant
																	).map(
																		(
																			plant,
																			i
																		) => {
																			return (
																				<tr
																					key={`${plant._id.rowNumber}_${i}`}
																				>
																					<td>
																						{
																							plant
																								._id
																								.rowNumber
																						}
																					</td>
																					<td>
																						{
																							plant
																								._id
																								.plantNumber
																						}
																					</td>
																					<td
																						className={
																							classesUI.cellFruit
																						}
																					>
																						{numberFormat(
																							plant.fruitPre
																						)}
																					</td>
																					<td
																						className={
																							classesUI.cellFruit
																						}
																					>
																						{numberFormat(
																							plant.fruitPost
																						)}
																					</td>
																					<td>
																						{numberFormat(
																							plant.dartPre
																						)}
																					</td>
																					<td>
																						{numberFormat(
																							plant.dartPost
																						)}
																					</td>
																					<td>
																						{numberFormat(
																							plant.twigPre
																						)}
																					</td>
																					<td>
																						{numberFormat(
																							plant.twigPost
																						)}
																					</td>
																				</tr>
																			);
																		}
																	)}
															</tbody>
														</table>
													</div>
												</BsCol>
											</BsRow>
										</CardContent>
									</Card>
								</BsCol>
							</BsRow>
						</>
					) : (
						<BsRow class="justify-content-between no-gutters">
							<BsCol class="col-md-12 text-center mt-3">
								<Alert
									variant="outlined"
									severity="warning"
									style={{
										backgroundColor: 'white',
									}}
								>
									<AlertTitle>{errorMessageState}</AlertTitle>
								</Alert>
							</BsCol>
						</BsRow>
					)}
				</BsContainer>
			) : (
				<BsContainer class="h-100">
					<BsRow class=" justify-content-md-center h-50 align-items-center">
						<BsCol class="col-3 text-center">
							<CircularProgressComponent />
						</BsCol>
					</BsRow>
				</BsContainer>
			)}
		</div>
	);
};

export default Dashboard;
