import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
// import { Context } from './context/Context';
import { RouteWithLayout } from './components';
import {
	// Main as MainLayout,
	Minimal as MinimalLayout,
	MinimalNoTopBar as MinimalNoTopBarLayout,
} from './layouts';

import { Dashboard as DashboardView, NotFound as NotFoundView } from './views';

const Routes = () => {
	return (
		<Switch>
			<Redirect from="/" to="/qr" exact />

			<RouteWithLayout
				component={DashboardView}
				// exact
				layout={MinimalNoTopBarLayout}
				path="/qr"
			/>

			<RouteWithLayout
				component={NotFoundView}
				exact
				layout={MinimalNoTopBarLayout}
				path="/not-found"
			/>
			<Redirect to="/not-found" />
		</Switch>
	);
};

export default Routes;
